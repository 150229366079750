/* global gform, gforms_recaptcha_recaptcha_strings, grecaptcha */
( ( gform, grecaptcha, strings ) => {

    const init = () => {
        let isInitialized = false;
        document.addEventListener( 'gform/postRender', ( event ) => {
            // Abort if already initialized.
            if ( isInitialized ) {
                return;
            }
            isInitialized = true;

            // Executing on AJAX validation.
            gform.utils.addAsyncFilter( 'gform/ajax/pre_ajax_validation', async ( data ) => {
                await executeRecaptcha( data.form );
                return data;
            });

            // Executing recaptcha on form submission or Next button click.
            gform.utils.addAsyncFilter( 'gform/submission/pre_submission', async ( data ) => {
                const recaptchaRequired = data.submissionType === gform.submission.SUBMISSION_TYPE_SUBMIT || data.submissionType === gform.submission.SUBMISSION_TYPE_NEXT;
                if ( recaptchaRequired && ! data.abort ) {
                    await executeRecaptcha( data.form );
                }
                return data;
            });
        });
    };

    const executeRecaptcha = async ( form ) => {
        const dataInput = form.querySelector( '.ginput_recaptchav3 .gfield_recaptcha_response' );

        // If reCAPTCHA fields can't be found, or recaptcha response is already set, don't execute reCAPTCHA.
        if ( ! dataInput || dataInput.value.length ) {
            return;
        }

        // Execute reCAPTCHA and set the token to the hidden input field.
        const token = await grecaptcha.execute( strings.site_key, { action: 'submit' } );
        if ( token.length && typeof token === 'string' ) {
            dataInput.value = token;
        }
    };

    init();

} )( gform, grecaptcha, gforms_recaptcha_recaptcha_strings );
